<template>
  <div>
    <el-dialog
      title="导出人员排班"
      :visible.sync="show"
      :show-close="false"
      width="500px"
    >
      <el-form
        ref="searchForm"
        :inline="true"
        label-width="100px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="文件名" prop="name">
          <el-input
            v-model="form.name"
            class="exportName"
            maxlength="30"
            placeholder="请输入文件名"
          ></el-input>
        </el-form-item>
        <el-form-item label="排班时间" prop="startEnd">
          <el-date-picker
            v-model="form.startEnd"
            type="daterange"
            align="right"
            :clearable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center;">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">添加到下载列表</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public";
export default {
  props: ["show", "exportForm"],
  data() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    var validateTime = (rule, value, callback) => {
      let startTime = dateFormat(new Date(this.form.startEnd[0]), "yyyy-MM-dd");
      let endTime = dateFormat(new Date(this.form.startEnd[1]), "yyyy-MM-dd");
      let data1 = Date.parse(startTime.replace(/-/g, "/"));
      let data2 = Date.parse(endTime.replace(/-/g, "/"));
      let datadiff = data2 - data1;
      let time = 30 * 24 * 60 * 60 * 1000;
      if (datadiff > time) {
        callback(new Error("时间不能超过31天", "提示"));
      } else if (!this.form.startEnd || !this.form.startEnd.length) {
        callback(new Error("请选择导出日期"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name:
          "人员排班" +
          year +
          (month < 9 ? "0" + month : month) +
          (day < 9 ? "0" + day : day),
        startEnd: [],
      },
      rules: {
        name: [
          { required: true, message: "文件名称不能为空", trigger: "blur" },
        ],
        startEnd: [
          { required: true, validator: validateTime, trigger: "blur" },
        ],
      },
      pickerOptions: {
        disabledDate: (time) => {
          // if (time.getTime() > (new Date().getTime() - 3600 * 1000 * 24)) {
          //   return true;
          // } else {
          return false;
          // }
        },
      },
    };
  },
  methods: {
    handleClose() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      this.form.name =
        "人员排班" +
        year +
        (month < 9 ? "0" + month : month) +
        (day < 9 ? "0" + day : day);
      let today = new Date(new Date().getTime());
      let yesterday = new Date(new Date().getTime() - 86400000 * 30);
      this.form.startEnd = [yesterday, today];
      this.$emit("update:show", false);
    },
    submit() {
      // if (!this.form.name) {
      //   this.$alert("", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return;
      // }
      // if (!this.form.startEnd || !this.form.startEnd.length) {
      //   this.$alert("请选择导出日期", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return;
      // }
      // /pdaScheduleDetail/pdaScheduleDetailExport
      this.$refs.searchForm.validate((v) => {
        if (v) {
          this.$axios
            .post("/acb/2.0/pdaScheduleDetail/pdaScheduleDetailExport", {
              data: {
                pdaManagerName: this.exportForm.name,
                pdaManagerRoleId: this.exportForm.role,
                deptId: this.exportForm.team,
                startWorkday: dateFormat(this.form.startEnd[0], "yyyy-MM-dd"),
                endWorkday: dateFormat(this.form.startEnd[1], "yyyy-MM-dd"),
                fileName: this.form.name,
              },
            })
            .then((res) => {
              if (res.state === 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.handleClose();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.handleClose();
              }
            });
        }
      });
    },
  },
  mounted() {
    let today = new Date(new Date().getTime());
    let yesterday = new Date(new Date().getTime() - 86400000 * 30);
    this.form.startEnd = [yesterday, today];
  },
};
</script>
<style lang="stylus" scoped>
.exportName
  width 350px
</style>
