<!-- 人员排班 -->
<template>
  <div class="personnelScheduling">
    <div class="searchWrapper">
      <el-form ref="searchForm " :inline="true" :model="form">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.full_name')">
              <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.role')">
              <el-select v-model="form.role" placeholder="请选择">
                <el-option value="" label="全部"></el-option>
                <el-option
                  v-for="item in roleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Belonging_operator')">
              <el-select
                v-model.trim="form.operationId"
                filterable
                size="15"
                @change="getOperationInfo"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading"
              v-if="$route.meta.authority.button.query"
              @click="
                page = 1;
                onSubmit();
              "
              >{{ $t("button.search") }}</el-button
            >
            <el-button type="info" icon="el-icon-delete" @click="clearForm">{{
              $t("button.reset")
            }}</el-button>
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button
              type="info"
              icon="el-icon-upload2"
              @click="exportsFile"
              >{{ $t("button.export") }}</el-button
            >
          </div>
        </div>

        <!--          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="show = true"
            v-if="$route.meta.authority.button.batchExport"
          >
		   导出
		  </el-button>-->
        <!-- <el-button type="primary" icon="el-icon-upload2" @click="show = true">{{ $t('button.export') }}</el-button>  -->
      </el-form>
    </div>
    <div class="tableWrapper bgFFF paddingB10">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          v-for="item in tableCols"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :formatter="item.formatter"
        >
        </el-table-column>
        <el-table-column
          :label="$t('list.operation')"
          align="center"
          width="90"
        >
          <template slot-scope="scope">
            <el-dropdown
              style="color: #20a0ff; cursor: pointer"
              @command="handleCommand($event, scope.row)"
            >
              <el-button style="padding: 0" type="text" size="small">
                操作 <i class="el-icon-arrow-down"
              /></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">编辑</el-dropdown-item>
                <el-dropdown-item command="2">查看</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Dialog :show.sync="show" :exportForm="form"></Dialog>
  </div>
</template>

<script>
import { exportExcelNew } from "@/common/js/public";
import Dialog from "./model";
import i18n from "@/i18n";
export default {
  name: "scheduling",
  components: {
    Dialog,
  },
  data() {
    return {
      show: false,
      loading: false,
      tenantList: [],
      form: {
        name: "",
        role: "",
        team: "",
        operationId: "",
      },
      roleList: [
        { value: "1", label: this.$t("list.Toll_collector") },
        { value: "2", label: this.$t("list.Inspector") },
      ],
      teamList: [],
      tableData: [],
      tableCols: [
        {
          prop: "pdaManagerName",
          label: this.$t("list.full_name"),
          width: "",
        },
        {
          prop: "pdaManagerRole",
          label: this.$t("list.role"),
          width: "",
          formatter: (row, column) => {
            let obj = {
              1: i18n.t("list.Toll_collector"),
              2: i18n.t("list.Inspector"),
            };
            return obj[row.pdaManagerRole];
          },
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "current",
          label: this.$t("list.scheduling_for_this_month"),
          width: "",
          formatter: (row, column) => {
            return row.current == 0
              ? i18n.t("list.nothing")
              : i18n.t("list.Ordered");
          },
        },
        {
          prop: "next",
          label: this.$t("list.scheduling_for_next_month"),
          width: "",
          formatter: (row, column) => {
            return row.next == 0
              ? i18n.t("list.nothing")
              : i18n.t("list.Ordered");
          },
        },
      ],
      page: 1,
      pageSize: 15,
      total: 0,
      rowData: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/scheduling" && to.query.isToEdit === "y") {
        this.onSubmit();
      }
    },
  },
  // 方法集合
  methods: {
    // 导出数据
    exportData() {
      this.$axios
        .post(
          "/acb/2.0/pdaScheduleDetail/export",
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            responseType: "blob",
          },
          {
            data: {
              pdaManagerName: this.form.name,
              pdaManagerRoleId: this.form.role,
              operationId: this.form.operationId,
            },
          }
        )
        .then(
          (res) => {
            // console.log('123-->', res);
            // const blob = new Blob([response.data]);
            const blob = new Blob([res], { type: "application/vnd.ms-excel" });
            const fileName = "xxx.xls";
            const linkNode = document.createElement("a");
            linkNode.download = fileName; // a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); // 生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); // 模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          },
          (err) => {
            console.log("error-->>", err);
          }
        );
    },
    // 所属运营商选择
    getOperationInfo(val) {
      this.form.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // pdaScheduleDetail/export 导出
    exportsFile() {
      let data = {
        pdaManagerName: this.form.name,
        pdaManagerRoleId: this.form.role,
        // deptId: this.form.team,
        operationId: this.form.operationId,
      };
      exportExcelNew("/acb/2.0/pdaScheduleDetail/export", data);
    },
    handleCurrentChange(v) {
      this.page = v;
      this.onSubmit();
    },
    onSubmit() {
      // acb/2.0/pdaScheduleDetail/page
      this.loading = true;
      this.$axios
        .get("/acb/2.0/pdaScheduleDetail/page", {
          data: {
            pageNum: this.page,
            pageSize: 15,
            pdaManagerName: this.form.name,
            pdaManagerRoleId: this.form.role,
            operationId: this.form.operationId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    clearForm() {
      this.form = {
        name: "",
        role: "",
        team: "",
        operationId: "",
      };
      this.$refs.searchForm.resetFields();
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.deptName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect() {},
    handleCommand(command, data) {
      this.rowData = data;
      if (command == 1) {
        this.$router.push({
          path: "/personnelSchedulingEdit",
          query: { ...this.rowData, command: 1 },
        });
      } else if (command == 2) {
        this.$router.push({
          path: "/personnelSchedulingEdit",
          query: { ...this.rowData, command: 2 },
        });
      }
    },
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    this.onSubmit();
  },
};
</script>
<style lang="stylus" scoped>
.searchWrapper {
  overflow: hidden;
  // margin-bottom: 20px;
}
</style>
